import { makeKeyboardEvent } from "#events";
import { useLayoutEffect, useState } from "react";
import {match} from "ts-pattern";
import { z } from "zod";
import {Components, Layouts} from "../../../types.js";
import type { Extras, Signals } from "../../../types.js";
import { NormalExtraOutput } from "./types.js";
import { CheckboxInput } from "#inputs";
import { classes } from "../../../stylesheet.js";
import { getTranslation, px2vh, px2vw } from "#utils";
import type { CSSProperties, FC } from "react";
import { printPrice } from "../../../utils.js";

export default (({ data, injected, templating, id, idList, style, signals }) => {
    const json = {} as Record<string, true | undefined>;
    const [checked, setChecked] = useState<boolean>(!!json[data.extra.id]);
    const onInput = makeKeyboardEvent(["ENTER"], () => {
        const new_value = !checked;
        if (new_value) {
            injected.configurator.addExtra(data.extra, true);
        }
        else {
            injected.configurator.removeExtra(data.extra.id);
        }
        setChecked(new_value);
        delete signals.config.value[data.extra.id];
        signals.config.value = { ...signals.config.value, ...injected.configurator.toJson() };
        return { stopPropagation: true, preventDefault: true };
    });
    const price = sessionStorage.getItem('pricesIncludeTax') === 'true' ? data.extra.options.priceWithTax : data.extra.options.priceWithoutTax;

    useLayoutEffect(() => {
        const extraConfig = signals.cart.value?.[JSON.parse(localStorage.getItem("orderToken") || '[]')]?.filter(product => window.location.pathname.indexOf(product.timestamp) > -1)?.[0]?.config;
        if (extraConfig?.[data.extra.id]) {
            injected.configurator.addExtra(data.extra, true);
            delete signals.config.value[data.extra.id];
            signals.config.value = { ...signals.config.value, ...injected.configurator.toJson() };
            setChecked(true);
        }
    }, [])

    useLayoutEffect(() => {
        if (sessionStorage.getItem("resetExtras")) {
            setChecked(false);
        }
    }, [sessionStorage.getItem("resetExtras")]);
    const currentOffset = idList.indexOf(id);
    const navMap = {
        up: currentOffset > 0 ? idList[currentOffset - 1].toString() : undefined,
        down: currentOffset !== idList.length-1 ? idList[currentOffset + 1].toString() : undefined
    };
    const onFocus =
        match(currentOffset)
            .with(0, ()=>(ev)=>ev.target.parentElement.scrollTo({top: 0}))
            .with(idList.length-1, ()=>(ev)=>ev.target.parentElement.scrollTo({top: Infinity}))
            .otherwise(()=>undefined);

    return (
        <injected.Navigable navMap={navMap}>
            <div onFocus={onFocus} onKeyDown={onInput} id={id} className={'navigable'} data-scroll-on-focus={true} style={{ ...classes('rounded', 'm-8', 'p-4', 'relative'), boxShadow: '0px 4px 16px 0px #95979A40', textAlign:'left', ...style }}>
                <div style={{ ...classes('inline-block'), width: 'calc(100% - 2.5rem)' }}>
                    <div style={{ ...classes('bold'), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" }}>{getTranslation(data.extra.translations, templating.languageCode, templating.projectLanguageCode)}</div>
                    <div>{printPrice(price / 100, data.options.currency_code)}</div>
                </div>
                <CheckboxInput checked={checked} id={"extra-" + data.extra.id + '-input'} height={`${px2vh(56)}vh`} style={{
                    ...classes('rounded'),
                    display: 'inline-block',
                    height: `${px2vh(56)}vh`,
                    width: `${px2vw(60)}vw`,
                    backgroundColor: '#eee',
                    top: `calc(50% - ${px2vh(56) / 2}vh)`,
                    right: `${px2vw(60) / 2}vw`,
                    color: 'black'
                }} />
            </div>
        </injected.Navigable>
    );
}) as FC<{
    data: { extra: z.infer<typeof NormalExtraOutput>, options: { currency_code: string } },
    id?: string,
    idList: (string | number)[],
    injected: {
        configurator: Extras.Configurator,
        Navigable: Components.Navigable
    },
    signals: {
        config: Signals.ExtraConfig
        cart: Signals.Cart
    }
    style: CSSProperties,
    templating: Parameters<Layouts.Details>[0]["templating"]
}>