import {classes} from "../stylesheet.js";
import {make_stylesheet, DEFAULT_STYLES, DEFAULT_STYLEHSEET} from "#figma";
import {px2vw, px2vh} from '#utils';
import {useLayoutEffect, useRef} from "react";
import mocks from "#mocks";
import type {Components, FC, Layouts} from "../types.js";
import merge from "ts-deepmerge";
import { capitalize, getOrderDeliveryDate, getOrderDeliveryLocation, getOrderState, getTranslation, printPrice, translate } from "../utils.js";

const DEFAULT_COVER_URL = "data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%22%20height%3D%22100%22%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22%23576575%22%20fill-opacity%3D%220.3%22%2F%3E%3C%2Fsvg%3E";

const default_list: Components.List = ({id, children, style})=><div id={id} style={style} >{children}</div>;
const default_link: Components.Link = ({to, children})=><a data-to={to}>{children}</a>;

// const lefts = {shop: "4vw", order: "14vw", delivery:"22.2vw", price: "40vw"}
const lefts = {shop: `${px2vw(72)}vw`, order: `${px2vw(545)}vw`, delivery:`${px2vw(894)}vw`, price: `${px2vw(1371)}vw`}

const OrderEntry: FC<{ languajeCode: string, data: Parameters<Layouts.MyOrder>[0]["data"]["order"][number], stylesheet: ReturnType<typeof make_stylesheet<"cart">>, injected: Parameters<Layouts.MyOrder>[0]["injected"],  texts: { [key: string]: string } }> = ({ data, stylesheet, injected, languajeCode, texts }) => {
    const Link = injected?.Link ?? default_link;
    const showLocation : boolean =  data.state !== "Cancelled" && data.state !=="Delivered" ? true : false;

    return(
        <Link key={data.id} to={`/myorders/${decodeURIComponent(data.id)}`}>
            <div id={`my-order-${data.id}`}  className="navigable" style={{...classes('inline-flex', 'w-full', 'rounded', "relative"), ...stylesheet.default('items'),...DEFAULT_STYLEHSEET.cart.default('items'), height: `${px2vh(136)}vh`, marginBottom:`${px2vh(16)}vh`}}>
                {data.shop_name === 'figma_string' ?
                     <div style={{...classes('relative', 'my-auto', 'font-700'),width:'27%', paddingLeft: '1vw'}}>{getTranslation([{languageCode:"es",name:"Tienda"},{languageCode:"en",name:"Shop"}], languajeCode)}</div>
                    :
                    <div style={{...classes('relative', 'my-auto', 'font-700'),width:'27%', paddingLeft: '1vw'}}>{data.shop_name}</div>
                }
                {//@ts-ignore
                data.totalQuantity === 'figma_string' ?
                    <div style={{...classes('relative', 'my-auto'),width:'19%'}}>nº {getTranslation([{languageCode:"es",name:"productos"},{languageCode:"en",name:"products"}], languajeCode)}</div>
                    :
                    <div style={{...classes('relative', 'my-auto'),width:'19%'}}>{data.totalQuantity} {translate(texts, data.totalQuantity===1? "product":"products")}</div>
                }
                {data?.shippingLines?.[0]?.shippingMethod?.code === "figma_string" ? 
                    <div style={{...classes('relative', 'my-auto'),width:'27%'}}>dd/mm/aaaa hh/mm<br />{getTranslation(JSON.parse(data.channels[0].customFields.pickup_location), languajeCode)}</div>
                    :
                    <div style={{...classes('relative', 'my-auto'),width:'27%'}}>{getOrderDeliveryDate(data.customFields, data.state, data.updatedAt, texts )}{showLocation && <br />}{getOrderDeliveryLocation(showLocation, data.shippingLines?.[0]?.shippingMethod?.code||"delivery", getTranslation(JSON.parse(data?.channels[0]?.customFields?.pickup_location && data.channels[0].customFields.pickup_location !=="" ? data.channels[0].customFields.pickup_location: "null")), texts)}</div>
                }
                <div style={{...classes('relative', 'my-auto'),width:'10%'}}>{printPrice((data.pricesIncludeTax ? data.totalWithTax:data.total) /100,data.currencyCode)}</div>
                {
                    data.state === 'figma_string' ?
                    <div style={{...classes('relative', 'my-auto','text-center'),width:'17%'}}>{getTranslation([{languageCode:"es",name:"Estado"},{languageCode:"en",name:"State"}],languajeCode)}</div>
                    :
                    <div style={{...classes('relative', 'my-auto','text-center'),width:'17%'}}>{capitalize(translate(texts, getOrderState(data.state, data.shippingLines?.[0]?.shippingMethod?.code||"delivery", data.channels[0].customFields.is_auto_accept_enabled)))}</div>
                }
            </div>
        </Link>
    )
}

export default (({data = mocks.myorders(), templating, signals, injected}) => {
    const List = injected?.List ?? default_list;
    const stylesheet = make_stylesheet(merge(DEFAULT_STYLES.roomshops, templating.css.roomshops, templating.css.cart, templating.css.products));
    const headers = [{name: translate(templating.texts,"shop_name"), left: lefts.shop},{name:translate(templating.texts, "order"), left: lefts.order},{name: translate(templating.texts, "delivery"), left: lefts.delivery},{name: translate(templating.texts, "total_price"), left: lefts.price}]

    useLayoutEffect(()=>{
        signals?.focus.value.replace(data.length>0 ? `my-order-${data[0].id}` : 'noFocus');
        return ()=>{
            injected?.cleanCache();
        }
    }, []);
    
    return(
        <>
            <div style={{...stylesheet.default('section1'), position: "fixed", width: '100vw', display: 'block', verticalAlign: 'middle', height: `${px2vh(152)}vh`, fontSize:`${px2vw(36)}vw`, fontWeight:"700", paddingLeft: '4vw', paddingTop:`${px2vh(55)}vh`, boxShadow: '0px 4px 16px 0px #95979A40'}}>{translate(templating.texts, "my_orders")}</div>
            <div style={{...stylesheet.default('section2'), position: "fixed" ,display:'block', width: '100vw', verticalAlign: 'middle', height: `${px2vh(80)}vh`, top: `${px2vh(152)}vh`, fontSize:`${px2vw(32)}vw`, boxShadow: '0px 4px 16px 0px #95979A40'}}>
                {headers.map(header=>
                    <div style={{...classes('absolute'),left:`${header.left}`, marginTop:`${px2vh(25)}vh`}}>{capitalize(header.name)}</div>                    
                )}
            </div>
            <div id="order-list" style={{...stylesheet.default('section3'),...classes( 'w-full'), overflowY:'scroll', position: 'fixed', top: `${px2vh(152+80)}vh`,zIndex:"20"}}>
                {data.length>0 ?
                    <List id={"orders"} style={{
                        paddingTop: `${px2vh(24)}vh`,
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: `${px2vh(850)}vh`,
                        width: `${px2vw(1777)}vw`,                        
                        fontSize:`${px2vw(30)}vw`,
                        position: "relative"
                    }}>
                        {data.reverse().map((item, index) => <OrderEntry data={item} key={index} stylesheet={stylesheet} texts={templating.texts} languajeCode={templating?.languageCode|| ""} injected={injected }/>)}
                        
                    </List>
                    :
                    <div id="order-history-empty" className="table w-full h-40" ><div className="table-cell vertical-middle w-full h-full text-center">{translate(templating.texts, "order-history-empty")}</div></div>
                }
            </div>
            
        </>
    );
}) as Layouts.MyOrder;
