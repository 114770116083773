import { makeKeyboardEvent } from "#events";
import { getTranslation, px2vh, px2vw, translate } from "#utils";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import { match, P } from "ts-pattern";
import type { Extras, Components, Signals, Layouts } from "../../../types.js";
import { classes } from "../../../stylesheet.js";
import type { CSSProperties, FC } from "react";
import { printPrice } from "../../../utils.js";

const NumberInput = (({ isFocused, id, height, className, style = {}, value = 0 }) => {
    return (
        <label tabIndex={0} style={{ ...style, position: 'absolute', textAlign: 'center' }} id={`${id}-label`} className={className}>
            <span style={{
                position: 'absolute', top: '0',
                left: '0',
                width: '100%',
                textAlign: 'center',
                lineHeight: height
            }}>{value}</span>
            {isFocused ? (
                <>
                    <div className="icon-chevron-up" style={{ position: 'absolute', fontSize: 'inherit', top: `-1.2em`, left: 'calc(50% - 0.5em)', color: 'black' }}></div>
                    <div className="icon-chevron" style={{ position: 'absolute', fontSize: 'inherit', bottom: `-1.2em`, left: 'calc(50% - 0.5em)', color: 'black' }}></div>
                </>
            ) : null}
        </label>
    );
}) as FC<{
    isFocused?: boolean,
    id?: string,
    className?: string,
    style?: Omit<CSSProperties, 'position'>,
    disabled?: boolean,
    value?: number,
    height?: string,
}>;

const MultiComboboxOption = (({ i, extra, templating, selectedOptions, canSelectMore, currencyCode, injected }) => {
    const [isLocked, setIsLocked] = useState(false);
    const keydownHandler = makeKeyboardEvent(['ENTER', 'ARROW_UP', 'ARROW_DOWN', 'BACK'], (keyName) => {
        const selectedOptionAmount = selectedOptions[extra.options.choices[i]!.id] ?? 0;
        if (!canSelectMore && !selectedOptionAmount && keyName !== 'BACK')
            return;

        match({ isLocked, keyName })
            .with({ isLocked: false, keyName: 'ENTER' }, () => {
                if (!canSelectMore && !selectedOptions[extra.options.choices[i]!.id])
                    return;
                setIsLocked(true)
            })
            .with(P.union({ isLocked: true, keyName: 'ENTER' }, { isLocked: true, keyName: 'BACK' }), () => {
                setIsLocked(false)
            })
            .with({ isLocked: true, keyName: 'ARROW_UP' }, () => {
                if (!canSelectMore)
                    return;
                injected.setSelectedOptions({ ...selectedOptions, [extra.options.choices[i]!.id]: selectedOptionAmount + 1 })
            })
            .with({ isLocked: true, keyName: 'ARROW_DOWN' }, () => injected.setSelectedOptions({ ...selectedOptions, [extra.options.choices[i]!.id]: selectedOptionAmount - 1 < 1 ? 0 : selectedOptionAmount - 1 }))
            .otherwise(() => 0);
        if (isLocked) {
            return { stopPropagation: true, preventDefault: true };
        }
    });

    const price = sessionStorage.getItem('pricesIncludeTax') === 'true' ? extra.options.choices[i]!.priceWithTax : extra.options.choices[i]!.priceWithoutTax;

    return (
        <div id={`extra-${extra.id}-choice-${i}-wrapper`}>
            <div className={'navigable'} id={`extra-${extra.id}-choice-${i}`} onKeyDown={keydownHandler} style={{
                ...classes('relative', 'rounded'),
                textAlign: 'left',
                height: `${px2vh(128)}vh`,
                width: `${px2vw(503)}vw`,
                marginTop: i === 0 ? `${px2vh(56)}vh` : `${px2vh(32)}vh`,
                paddingTop: `${px2vh(16)}vh`,
                paddingBottom: `${px2vh(16)}vh`,
                marginLeft: 'auto',
                marginRight: 'auto',
                background: (canSelectMore || (Object.keys(selectedOptions)).includes(extra.options.choices[i]!.id)) ? '#FFF' : '#E9EDF0',
                boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
            }}>
                <div style={{
                    fontSize: `${px2vw(28)}vw`,
                    marginLeft: `${px2vw(24)}vw`,
                    width: '75%',
                    fontWeight: 'bold',
                }}>{getTranslation(extra.options.choices[i]!.translations, templating.languageCode, templating.projectLanguageCode)}</div>
                <div style={{
                    fontSize: `${px2vw(24)}vw`,
                    marginLeft: `${px2vw(24)}vw`
                }}>{printPrice(price / 100,currencyCode)}</div>
                <NumberInput isFocused={isLocked} id={`extra-${extra.id}-${i}-input`} style={{
                    ...classes('rounded'),
                    width: `${px2vw(64)}vw`,
                    height: `${px2vh(56)}vh`,
                    backgroundColor: '#F5F6F8',
                    border: '2px solid #D3DAE1',
                    top: `${px2vh(30)}vh`,
                    right: `${px2vw(36)}vw`,
                    color: (canSelectMore || (Object.keys(selectedOptions)).includes(extra.options.choices[i]!.id)) ? 'black' : '#91A3B5'
                }} height={`${px2vh(56)}vh`} value={selectedOptions[extra.options.choices[i]!.id] ?? 0} />
            </div>
        </div>
    );
}) as FC<{
    extra: Extras.Multicombobox,
    canSelectMore: boolean,
    currencyCode: string,
    i: number,
    selectedOptions: Record<string, number>
    injected: {
        configurator: Extras.Configurator,
        setSelectedOptions: (opts: Record<string, number>) => void
    },
    signals: {
        config: Signals.ExtraConfig
        cart: Signals.Cart,
    },
    templating: Parameters<Layouts.Details>[0]["templating"]
}>

const MulticomboboxAddButton: FC<{templating: Parameters<Layouts.Details>[0]["templating"],  injected: {onSubmit: (()=>void)}}> = ({templating, injected})=>{
    return (
        <div className={'navigable text-center'} id={'add-combobox-button'} onClick={injected.onSubmit} style={{
            ...classes('absolute', 'w-full', 'rounded'),
            border: '0.1vw solid rgb(211, 218, 225)',
            color: '#576575',
            bottom: `-${px2vh(64 + 62)}vh`,
            height: `${px2vh(64)}vh`,
        }}>
            <span style={{ ...classes('rounded'), fontSize: `${px2vw(32)}vw`, width: '100%', textAlign: 'center', lineHeight: `${px2vh(64)}vh` }}>
                {translate(templating.texts, 'Add extra')}
            </span>
        </div>
    );
}

const MultiComboboxSelectionModalBody = (({extra, injected, currencyCode, selectedOptions, signals, templating }) => {
    const selectionCount = Object.values(selectedOptions ?? {}).reduce((acc, cur) => acc + cur, 0);
    const canSelectMore = extra.options.max === 0 || (!!extra.options.max && selectionCount < extra.options.max);
    useEffect(() => {
        signals.focus.value.stack(`extra-${extra.id}-choice-0`);
        return () => signals.focus.value.unstack();
    }, []);
    const handler = makeKeyboardEvent(["BACK", "ARROW_DOWN", "ARROW_UP", "ARROW_LEFT", "ARROW_RIGHT"], (key) => {
        if (key === "BACK"){
            injected.onBack();
        }
        return { stopPropagation: true, preventDefault: true };
    });
    const ref = useRef<HTMLDivElement>(null);
    return (
        <div onKeyDown={handler} ref={ref} style={{ ...classes('relative', 'h-full') }}>
            <div style={{ height: `${px2vh(549)}vh`, overflow: 'auto', scrollBehavior: 'smooth' }}>
                {extra.options.choices.map((_, i) => {
                    return (
                        <injected.Navigable autoscroll={{y: 128+(56+16)/2}} navMap={{
                            up: i === 0 ? 'btn-exit' : `extra-${extra.id}-choice-${i-1}`,
                            down: i === extra.options.choices.length -1 ? 'add-combobox-button' : `extra-${extra.id}-choice-${i+1}`
                        }}>
                            <MultiComboboxOption templating={templating} signals={signals} extra={extra} selectedOptions={selectedOptions} currencyCode={currencyCode} canSelectMore={canSelectMore} i={i} injected={injected}/>
                        </injected.Navigable>
                    );
                })}
                <injected.Navigable navMap={{up: `extra-${extra.id}-choice-${extra.options.choices.length-1}`}}>
                    <MulticomboboxAddButton templating={templating} injected={injected}/>
                </injected.Navigable>
            </div>
        </div>
    );
}) as FC<{
    extra: Extras.Multicombobox,
    currencyCode: string,
    selectedOptions: Record<string, number>,
    injected: {
        setSelectedOptions: (opts: Record<string, number>) => void,
        configurator: Extras.Configurator,
        onBack: () => void,
        onSubmit: () => void
        VerticalList: Components.List,
        Navigable: Components.Navigable
    },
    signals: {
        focus: Signals.Focus,
        config: Signals.ExtraConfig,
        cart: Signals.Cart
    },
    templating: Parameters<Layouts.Details>[0]["templating"]
}>
export default (({ data, style, id, idList, injected, signals, templating }) => {
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [initialOptions, setInitialOptions] = useState((signals.config.peek()[data.extra.id] ?? []) as Extras.Config.Multicombobox);
    const selectedOptions: Extras.Config.Multicombobox = signals.config.value[data.extra.id] as Record<string, number> ?? {};
    const setSelectedOptions = (opts: Extras.Config.Multicombobox)=>{
        injected.configurator.addExtra(data.extra, opts);
        signals.config.value = { ...signals.config.value, ...injected.configurator.toJson() };
    }
    const onSubmit = () => {
        const keys = Object.keys(selectedOptions);
        const sanitizedOptions = keys.reduce((acc, cur)=>{
            if (!selectedOptions[cur])
                delete acc[cur];
            return acc;
        }, selectedOptions);
        setSelectedOptions(sanitizedOptions);
        setModalVisible(false);
    }
    const onKeyDown = makeKeyboardEvent(['ENTER'], () => {
        setModalVisible(!isModalVisible);
        setInitialOptions((signals.config.peek()[data.extra.id] ?? []) as Extras.Config.Multicombobox);
        return { stopPropagation: true, preventDefault: true };
    });
    const onModalKeyDown = makeKeyboardEvent(['ARROW_DOWN', 'HOME', 'ARROW_UP'], (key) => {
        if (key === 'ARROW_DOWN' && signals.focus.value.current === 'btn-exit') {
            signals.focus.value.replace(`extra-${data.extra.id}-choice-0`);
            return { stopPropagation: true, preventDefault: true };
        }
        if (key === 'ARROW_UP' && signals.focus.value.current === 'btn-exit') {
            return { stopPropagation: true, preventDefault: true };
        }
        if (key === 'HOME') {
            return { stopPropagation: true, preventDefault: true };
        }
    });
    const backOverride = makeKeyboardEvent(['BACK'], () => {
        setModalVisible(false);
        return { stopPropagation: isModalVisible, preventDefault: isModalVisible };
    });
    const onBack = () => {
        console.log({initialOptions});
        setSelectedOptions(initialOptions);
        setModalVisible(false);
    };
    const subtitle = match(data.extra.options)
        .with({ min: P.number.and(P.not(0)), max: P.number.and(P.not(0)) }, (options) => options.min !== options.max ? `${translate(templating.texts, 'select-min-max-extras').replace('{min}', options.min.toString()).replace('{max}', options.max.toString())}` : `${translate(templating.texts, options.max === 1 ? 'select-x-extras-singular' : 'select-x-extras-plural').replace('{units}', options.max.toString())}`)
        .with({ min: P.number.and(P.not(0)) }, (options) => `${translate(templating.texts, options.min === 1 ? 'select-min-extra-singular' : 'select-min-extra-plural').replace('{min}', options.min.toString())}`)
        .with({ max: P.number.and(P.not(0)) }, (options) => `${translate(templating.texts, options.max === 1 ? 'select-max-extra-singular' : 'select-max-extra-plural').replace('{max}', options.max.toString())}`)
        .otherwise(() => '');

    useLayoutEffect(() => {
        const extraConfigInCart = signals?.cart?.value?.[JSON.parse(localStorage.getItem("orderToken") || '[]')]?.filter(product => window.location.pathname.indexOf(product.timestamp) > -1)?.[0]?.config;
        let selectedOpts: Record<string, number> = {};

        data?.extra?.options?.choices.forEach(choice => {
            if (!choice.available) {
                return;
            }
            //@ts-ignore
            const editingVal = extraConfigInCart?.[data.extra.id]?.[choice.id];
            if (editingVal) {
                selectedOpts[choice.id] = editingVal;
            }
        });

        Object.keys(selectedOpts).reduce((acc, cur)=>{
            if (!acc[cur])
                delete selectedOpts[cur];
            return acc;
        }, selectedOpts);

        if (Object.keys(selectedOpts).length > 0) {
            injected.configurator.addExtra(data.extra, selectedOpts);
            signals.config.value = { ...signals.config.value, ...injected.configurator.toJson() };
        }
    }, []);

    const extrasPrice: number = Object.keys(signals.config.value[data.extra.id] || {}).map((choiceId) => data.extra.options.choices.find(y => y.id === choiceId)).reduce((acc, cur) => acc + (sessionStorage.getItem('pricesIncludeTax') === 'true' ? cur!.priceWithTax : cur!.priceWithoutTax) * (selectedOptions?.[cur!.id] ?? 0), 0);
    const numSelected: number = Object.values(signals.config.value[data.extra.id]|| []).reduce((acc, cur)=>acc+cur, 0);
    const currentOffset = idList.indexOf(id);
    const navMap = {
        up: currentOffset > 0 ? idList[currentOffset - 1].toString() : undefined,
        down: currentOffset !== idList.length-1 ? idList[currentOffset + 1].toString() : undefined
    };

    return (
        <injected.Navigable navMap={navMap}>
            <div onKeyDown={backOverride}>
                {isModalVisible
                    ?
                    <injected.Navigable navMap={{ up: 'searchNext', down: 'searchNext', left: 'stopPropagation', right: 'stopPropagation' }}>
                        <div onKeyDown={onModalKeyDown}>
                            <injected.Modal
                                title={getTranslation(data.extra.translations, templating.languageCode, templating.projectLanguageCode)}
                                subtitle={subtitle}
                                body={<MultiComboboxSelectionModalBody signals={signals} templating={templating} extra={data.extra} selectedOptions={selectedOptions} injected={{ ...injected, onSubmit, onBack, setSelectedOptions }} currencyCode={data.options.currency_code} />}
                                width={`${px2vw(736)}vw`}
                                height={`${px2vw(1008)}vw`}
                                backBtn={true}
                                backAction={onBack}
                                firstFocus={`extra-${data.extra.id}-choice-0`}
                            />
                        </div>
                    </injected.Navigable>
                    : null}
                <div id={id} onKeyDown={onKeyDown} className={'navigable'} style={{ ...classes('rounded', 'm-8', 'p-4', 'relative'), boxShadow: '0px 4px 16px 0px #95979A40', textAlign:'left', ...style }}>
                    <div style={{ ...classes('inline-block'), width: 'calc(100% - 2.5rem)' }}>
                        <div style={{whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis", ...classes('bold')}}>{getTranslation(data.extra.translations, templating.languageCode, templating.projectLanguageCode)}</div>
                        {
                            data.extra.options.min ===0  && numSelected === 0
                                ? <div>{translate(templating.texts, 'Not selected')}</div>
                                : data.extra.options.min && (numSelected === 0  || numSelected<data.extra.options.min)
                                    ?<div>{translate(templating.texts, 'Required')}</div>
                                    :<div>{translate(templating.texts, `x-selected-${numSelected === 1 ? 'singular' : 'plural'}`).replace('{units}', numSelected)} ({printPrice(extrasPrice / 100, data.options.currency_code)})</div>
                        }
                        {/* <div>{numSelected ? `${translate(templating.texts, `x-selected-${numSelected === 1 ? 'singular' : 'plural'}`).replace('{units}', numSelected.toString(10))} (${printPrice(extrasPrice / 100,data.options.currency_code)})` : translate(templating.texts, data.extra.options.min && data.extra.options.min > numSelected ? 'Required' : 'Select...')}</div> */}
                    </div>
                    <div className="icon icon-chevron-right" style={{ height: '2rem', width: '2rem', position: 'absolute', top: 'calc(50% - 1rem)', right: '1.5rem'}} />
                </div>
            </div>
        </injected.Navigable>
    );
}) as FC<{
    data: {
        extra: Extras.Multicombobox,
        options: { currency_code: string }
    },
    id?: string,
    idList: (string | number)[],
    injected: {
        configurator: Extras.Configurator,
        Modal: Components.Modal,
        VerticalList: Components.List,
        Navigable: Components.Navigable
    },
    signals: {
        focus: Signals.Focus,
        config: Signals.ExtraConfig
        cart: Signals.Cart
    }
    style: CSSProperties,
    templating: Parameters<Layouts.Details>[0]["templating"]
}>