import table from "./table.js";
import {classes} from "../../../stylesheet.js";
import {px2vw} from "#utils";
import type {FC, CSSProperties} from "../../../types.js";
import { px2vh } from "../../../utils.js";

export default (({name, style = {}})=>{
    return (
        <div style={{...classes('rounded'), height: `${px2vw(58)}vw`, width: `${px2vw(58)}vw`, marginTop: `${px2vh(14)}vh`, backgroundColor: 'white', ...style}}>
            <div className={table[name].icon} style={{fontSize: `${px2vw(58)}vw`, color: table[name].color}}></div>
        </div>
    )
}) as FC<{name: keyof typeof table, style?: CSSProperties}>