import {classes} from "../stylesheet.js";
import {make_stylesheet, DEFAULT_STYLES, DEFAULT_STYLEHSEET} from "#figma";
import {px2vw, px2vh, capitalize, translate} from '#utils';
import {useLayoutEffect, useRef} from "react";
import mocks from "#mocks";
import type {Components, Layouts} from "../types.js";
import merge from "ts-deepmerge";
import { getCartNumProducts } from "../utils.js";

const DEFAULT_COVER_URL = "data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%22%20height%3D%22100%22%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill%3D%22%23576575%22%20fill-opacity%3D%220.3%22%2F%3E%3C%2Fsvg%3E";

const default_list: Components.List = ({id, children})=><div id={id}>{children}</div>;
const default_link: Components.Link = ({to, children})=><a data-to={to}>{children}</a>;
const default_background_image: Components.BackgroundImage = ({children})=><>{children}</>;
export default (({data, templating, signals, injected}) => {
    if (!data)
        data = mocks.roomshops(templating.languageCode);
    const Link = injected?.Link ?? default_link;
    const List = injected?.List ?? default_list;
    const stylesheet = make_stylesheet(merge(DEFAULT_STYLES.roomshops, templating.css.roomshops));
    const itemRefs = useRef<Array<HTMLDivElement>>([]);
    const BackgroundImage = injected?.BackgroundImage ?? default_background_image;

    useLayoutEffect(()=>{
        //@ts-ignore
        const focusedItem = document.getElementById(signals?.focus.value?.current);
        //@ts-ignore
        focusedItem && signals?.focus.value.replace(signals?.focus.value?.current);
        if (data?.[0]?.token && !focusedItem){
            signals?.focus.value.replace(`shop-${encodeURIComponent(data[0].token)}`);
        }
    }, []);
    return(
        <>
            <div style={{...stylesheet.default('section1'), ...classes("text-center", 'relative'), width: '100vw', display: 'table-cell', verticalAlign: 'middle', height: `${px2vh(152)}vh`, fontSize:`${px2vw(36)}vw`, boxShadow: '0px 4px 16px 0px #95979A40'}}>{templating.strings.welcomeMessage}</div>

            { templating.strings.showMyOrders &&
            <Link to={"/myorders"}>
                <div className="navigable sales-buttons"
                    id="btn-myorders"
                    style={{
                        ...classes('text-center', 'rounded'),
                        position: 'absolute',
                        top: `${px2vh(48)}vh`,
                        right: `${px2vw(72)}vw`,
                        fontSize:`${px2vh(24)}vh`,
                        paddingBottom: `calc(${px2vh(12)}vh - 0.2vw)`,
                        paddingTop: `calc(${px2vh(12)}vh - 0.2vw)`,
                        borderColor: '#D3DAE1',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        width: `${px2vw(186)}vw`,
                        height: `${px2vh(56)}vh`,
                        ...stylesheet.default('buttons'),
                    }}>{translate(templating.texts, "my_orders")}</div>
            </Link>

            }
            <div id="shops" style={{...stylesheet.default('section3'), ...classes('text-center', 'relative'), whiteSpace: 'nowrap', overflowX:'scroll', padding: `${px2vh(32)}vh 0`, paddingTop: `${px2vh(161)}vh`, height: `${px2vh(936)}vh`}}>
                <List id="shops">
                    {data.map((item, i, arr)=>{
                        const bgImage = item.customFields.cover_url || DEFAULT_COVER_URL;
                        const cartProducts = getCartNumProducts(signals?.cart?.[item.token]||[]);

                        return (<Link to={`/roomshops/${decodeURIComponent(item.token)}/categories`}>
                                    <div data-highlight="items" id={'shop-'+encodeURIComponent(item.token)} onKeyDown={()=> {sessionStorage.setItem("roomshopId", item.id)}}  onMouseDown={()=>{sessionStorage.setItem("roomshopId", item.id)}}
                                         tabIndex={0}
                                         className={'navigable btn-focusOutline'}
                                         data-scroll-on-focus={true}
                                         ref={el => itemRefs.current[i] = el as HTMLDivElement}
                                         style={{
                                             ...DEFAULT_STYLEHSEET.roomshops.default('items'),
                                             ...(document.activeElement === itemRefs.current?.[i] ? stylesheet.focus('items') : stylesheet.default('items')),
                                             ...classes('inline-block', "rounded", "object-cover", 'relative'),
                                             width: `${px2vw(420)}vw`,
                                             height: `${px2vh(584)}vh`,
                                             marginLeft: `${px2vw(32 + (i === 0 ? 72 : 0))}vw`,
                                             marginRight: `${px2vw(32 + (i === arr.length-1 ? 72 : 0))}vw`
                                        }}
                                    >
                                        {cartProducts ?
                                             <div style={{
                                                ...classes('absolute'),
                                                height: `${px2vh(29 + 4 + 16)}vh`,
                                                fontSize: `${px2vw(24)}vw`,
                                                lineHeight: `calc(${px2vh(29 + 4 + 16)}vh - ${px2vw(7)}vw)`,
                                                borderRadius: `${px2vh(4)}vh`,
                                                padding: `${px2vh(1)}vh ${px2vw(8 + 4)}vw`,
                                                right: `${px2vh(15)}vh`,
                                                top: `${px2vh(15)}vh`,
                                                backgroundColor: 'rgba(255, 255, 255, 0.80)',
                                                zIndex: 21
                                            }}>
                                                <span className="icon icon-cart" style={{ verticalAlign: 'middle', lineHeight: 1.3, fontSize: `${px2vw(32)}vw` }} />&nbsp; {cartProducts}
                                            </div>:null}
                                        <BackgroundImage src={bgImage}>
                                            <div ref={useRef(null)} style={{...classes('absolute', 'w-full', 'h-full'), zIndex: 20}}></div>
                                        </BackgroundImage>

                                        <div style={{...classes('absolute'), zIndex: 20, width: `${px2vw(420)}vw`, height: `${px2vh(291)}vh`, marginTop: `${px2vh(163)}vh`, backgroundColor: 'rgba(255, 255, 255, 0.80)'}}>
                                            <div className="title" style={{...classes('bold'), margin: `${px2vh(32)}vh 5%`, fontSize: `${px2vw(30)}vw`, wordBreak: 'break-word', whiteSpace: 'normal',  overflow: "hidden",
                                                        width: "90%",
                                                        display: 'table'}}>
                                                        <div style={{display:"table-cell",
                                                            height:'7vh',
                                                            verticalAlign:"bottom"}}>
                                                            <div style={{ textOverflow: "ellipsis",
                                                                display: "-webkit-box",
                                                                webkitLineClamp: 2,
                                                                webkitBoxOrient: "vertical",
                                                                verticalAlign:"bottom"}}>{item.name}
                                                            </div>
                                                        </div>
                                                </div>
                                            <div style={{ fontSize: `${px2vw(20)}vw`}}>{capitalize(
                                                item.customFields.delivery_schedule?.indexOf('figma_string') !== -1 ? translate(templating.texts, 'Delivery time')
                                                    : translate(templating.texts, item.customFields.delivery_schedule ? 'Delivery hours' : 'Delivery 24 hours')

                                                )}</div>
                                            {(item.customFields.delivery_schedule && item.customFields.delivery_schedule !== '"figma_string"') ? <div style={{fontSize: `${px2vw(20)}vw`}}>{JSON.parse(item?.customFields?.delivery_schedule || '[]').map((x: {startTime: string, endTime: string}, i: number)=><div key={i}>{x.startTime} - {x.endTime}</div>)}</div> : null}
                                        </div>
                                    </div>
                    </Link>);
                    })
                    }
                </List>
            </div>
        </>
    );
}) as Layouts.Roomshops;
